<template>
    <div class="video_player" v-show="coreStore.videoPlayerShown === true">
      <div class="close_video" @click="closeAll">X</div>
      <video-player
        :src="coreStore.videoSelected === 0 ? '/assets/video/museo/01_historia.mp4':
              coreStore.videoSelected === 1 ? '/assets/video/museo/02_terreno.mp4' :
              coreStore.videoSelected === 2 ? '/assets/video/museo/03_elaboracion.mp4':
              coreStore.videoSelected === 3 ? '/assets/video/museo/04_cangas_hoy.mp4':
              coreStore.videoSelected === 4 ? '/assets/video/lagar/05_prensa.mp4':
              coreStore.videoSelected === 5 ? '/assets/video/museo/03a_la_vinha.mp4':
              coreStore.videoSelected === 6 ? '/assets/video/museo/03b_elaboracion.mp4':
              'null'"
        controls
        :playsinline="true"
        :fluid="true"
        :loop="false"
        :volume="0.6"
        @ended="closeVideo"
      />
    </div>
  </template>
  
  <script>
  import { useCoreStore } from '@/stores/coreStore';
  import { VideoPlayer } from '@videojs-player/vue';
  import 'video.js/dist/video-js.css'
  export default {
    name: "VideoPlayerContainer",
    components:{
      VideoPlayer
    },
    setup(){
        const coreStore = useCoreStore();
        return {
            coreStore
        }
    },
    data() {
      return {
      }
    },
    methods: {
      closeVideo:function(){
        this.coreStore.videoPlayerShown = false;

      },
      closeAll: function(){
        this.coreStore.videoPlayerShown = false;
        document.getElementById('vjs_video_3_html5_api').pause();
        document.getElementById('vjs_video_3_html5_api').currentTime = 0;
      }
    },
    mounted() {
    } 
  }
  </script>
  
  <style>
    .video_player{
        position: absolute;
        z-index: 9999999;
        width: 90%;
        /*height: 90%;*/
        background-color: #1E1E1E;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
    }
    .close_video{
        width: 36px;
        height: 36px;
        border-radius: 18px;
        align-self: flex-end;
        position: absolute;
        top: -18px;
        right: -18px;
        background: #CA2121;
        color: #fff;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-weight: 700;
    }

    .vjs-big-play-button{
      top:50%!important;
      left:50%!important;
      transform: translate(-50%,-50%);
    }
  </style>
  