<template>
<div class="modal_container" v-show="this.coreStore.isModalShown === true">
    <div class="modal_title"><h1>{{ this.coreStore.modal_title }}</h1></div>
    <div class="modal_content">{{ this.coreStore.modal_description }}</div>
    <button id="ar_view" @click="arview()">Ver en AR</button>
    <button class="ok_button" @click="close()">Salir</button>
</div>
</template>

<script>
const barril = 'https://studio.onirix.com/modelviewer/ea5da500c10b4aa3af7b733d24d6a43c?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY0ODQ1Niwicm9sZSI6OCwiaWF0IjoxNjc1OTE0MTU3fQ.ebMh7_QHf_QS7zFNzSxzqjwH6EtV-mBMEHjYDWjbT58';
const jarra = 'https://studio.onirix.com/modelviewer/28ea4dc2cead4d49bb89cb5b08eeaaa3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY1NzkxOSwicm9sZSI6OCwiaWF0IjoxNjc2Mzc2NjAwfQ.i4pftXa-WpQAzApIc1m3QhwbAACtn4tNmA1GZGcfiPs';
        const cesto = 'https://studio.onirix.com/modelviewer/39a31cf849ab408b939d5b77fd612e9e?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY0ODQ1OCwicm9sZSI6OCwiaWF0IjoxNjc1OTE0NTc4fQ.yAPc6mEC6qcbvJk7JNixXcZZ_w1cjnNPfbx0UXDxrEc';

import {useCoreStore} from '@/stores/coreStore';
export default{
    name:'ModalComponent',
    setup(){
        const coreStore = useCoreStore();
        return{
            coreStore
        }
    },
    methods:{
        close: function(){
            this.coreStore.isModalShown = false;
        },
        arview: function(){
            if(this.coreStore.modal_title  === 'Goxo'){
                window.open(cesto, '_blank').focus();
            }else if(this.coreStore.modal_title === 'Barrica'){
                window.open(barril, '_blank').focus();
            }else if(this.coreStore.modal_title === 'Puchera'){
                window.open(jarra, '_blank').focus();
            }
        }
    }
}
</script>

<style scoped>
.modal_container{
    width: 90%;
    height:auto;
    padding:20px;
    display:flex;
    gap:15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #772929;
}

.modal_container>.modal_title{
    font-family: 'Inter';
    color:#f4f4f4;
    background-color: #CA5C21;
    padding:15px;
    clip-path: polygon(0 12%, 100% 12%, 91% 100%, 8% 100%);
}

.modal_container>.modal_content{
    color:#f4f4f4;
    font-family:'Inter';
}

#ar_view{
    color:#f4f4f4;
    font-family:'Inter';
    border:none;
    outline:none;
    background-color: #CA5C21;
    padding:10px 20px;
    font-weight: 600;
    width: 120px;
}

.ok_button{
    color:#f4f4f4;
    font-family:'Inter';
    border:none;
    outline:none;
    background-color: #CA5C21;
    padding:10px 20px;
    font-weight: 600;
    width: 120px;
}
</style>