<template>
  <div class="start_screen" style="background-image: url('./assets/img/bg.png');" v-show="this.routeStore.currentRoute == 0">
    <div class="main_logo">
      <img src="/assets/img/logo_museo.png" alt="">
    </div>
    <div class="buttons">
      <button class="button_primary" @click="startExperience()">ENTRAR</button>
      <button class="button_secondary" @click="this.coreStore.instructionsOpened = true">INSTRUCCIONES</button>
    </div>
    
    <div class="misc_logos">
      <div class="powered_by">
      <img src="/assets/img/powered.png" alt="">
      </div>
      <div class="logos">
      <img src="/assets/img/logo_principado.png" alt="">
      <img src="/assets/img/logo_cangas.png" alt="">
      <img src="/assets/img/logo_asturias.png" alt="">
    </div>
    </div>
  </div>
</template>

<script>
import {useRouteStore} from '@/stores/RouteStore';
import { useCoreStore } from '@/stores/coreStore';
export default {
  name: 'StartScreen',
  props: {
    

  },
  setup(){
    const routeStore = useRouteStore();
    const coreStore = useCoreStore();
    return{
      routeStore, coreStore
    }
  },
  methods:{
    startExperience: function(){
      this.routeStore.increment()
    },
    showInstructions: function(){

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.start_screen{
  position:absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main_logo{
  width: 100%;
}
.main_logo>img{
  width: 100%;
  padding: 2.5%;
}

.buttons{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.powered_by{
  display: flex;
  justify-content: center;
  align-items: center;
}

.misc_logos{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; 
  gap:10px;
}
.misc_logos>img{
  align-self: center;
}

.logos{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
