Copy code
<template>
  <div class="instructions" v-if="this.coreStore.instructionsOpened">
    <div class="top_bar_ins">
      <div @click="goBack()" class="back_button">
        <img src="/assets/img/back.svg" alt="">
      </div>
      <img src="/assets/img/logo_museo.png" alt="">
    </div>
    <h1 class="title" v-if="this.coreStore.instructionsPage===0">Sonido</h1>
      <h1 class="title" v-if="this.coreStore.instructionsPage===1">Exploración de espacios 360</h1>
      <h1 class="title" v-if="this.coreStore.instructionsPage===2">Acceso a los espacios</h1>
      <h1 class="title" v-if="this.coreStore.instructionsPage===3">Realidad Aumentada</h1>


      <div class="text_block" v-if="this.coreStore.instructionsPage===0">
        <p>
          Esta experiencia contiene varios elementos sonoros (vídeos explicativos, sonido ambiente, etc...), por lo que recomendamos activar el sonido y el uso de auriculares para disfrutarla al 100%.
        </p>
      </div>
      
      <div class="text_block" v-if="this.coreStore.instructionsPage===1">
        <p>El movimiento de la cámara puede realizarse de dos formas: </p>
        <ul>
          <li>Mediante el giroscopio, orientando el dispositivo en cualquier dirección. </li>
          <li>Con interacción directa, deslizando el dedo hacia la dirección deseada.</li>
        </ul>
      </div>

      <div class="text_block" v-if="this.coreStore.instructionsPage===2">
        <p>Pulse sobre los botones para acceder a los diferentes espacios.</p>
        <p>Para regresar al espacio anterior, pulse el botón “Volver”.</p>
      </div>

      <div class="text_block" v-if="this.coreStore.instructionsPage===3">
        <p>Mueva el dispositivo en círculos, mirando hacia el suelo o una superficie plana.</p>
        <p>Una vez se haya situado el objeto, éste puede ser escalado y desplazado pulsando sobre él con dos dedos.</p>
      </div>

      <div class="images_block" v-show="this.coreStore.instructionsPage===0">
        <div class="img_text">
        <img src="/assets/img/i_0_a.png" alt="">
        <p>Sonido activado</p>
        </div>

        <div class="img_text">
        <img src="/assets/img/i_0_b.png" alt="">
        <p>Uso de auriculares</p>
        </div>
      </div>

      <div class="images_block" v-show="this.coreStore.instructionsPage===1">
        <div class="img_text">
        <img src="/assets/img/i_1_a.png" alt="">
        <p>Exploración mediante giroscopio</p>
        </div>

        <div class="img_text">
        <img src="/assets/img/i_1_b.png" alt="">
        <p>Exploración táctil</p>
        </div>
      </div>

      <div class="images_block" v-show="this.coreStore.instructionsPage===2">
        <div class="img_text">
        <img src="/assets/img/i_2_a.png" alt="">
        <p>Pulse para acceder</p>
        </div>

        <div class="img_text">
        <img src="/assets/img/i_2_b.png" alt="">
        <p>Pulse para volver</p>
        </div>
      </div>

      <div class="images_block" v-show="this.coreStore.instructionsPage===3">
        <div class="img_text">
        <img src="/assets/img/i_3_a.png" alt="">
        <p>Mueva para situar</p>
        </div>

        <div class="img_text">
        <img src="/assets/img/i_3_b.png" alt="">
        <p>Pulse para escalar/mover</p>
        </div>
      </div>
      <div class="points">
        <div :style="this.coreStore.instructionsPage===0 ? 'opacity:1' : 'opacity:.5'"></div>
        <div :style="this.coreStore.instructionsPage===1 ? 'opacity:1' : 'opacity:.5'"></div>
        <div :style="this.coreStore.instructionsPage===2 ? 'opacity:1' : 'opacity:.5'"></div>
        <div :style="this.coreStore.instructionsPage===3 ? 'opacity:1' : 'opacity:.5'"></div>
      </div>
      <button class="next_button" @click="next()">{{this.coreStore.instructionsPage===2 ? 'COMENZAR' : 'SIGUIENTE'}}</button>
  </div>
</template>

<script>
import { useCoreStore } from '@/stores/coreStore'
import { useRouteStore } from '@/stores/RouteStore';
export default {
  name: "InstructionsComponent",
  data() {
    return {
      // Your data goes here
    }
  },
  setup(){
    const coreStore = useCoreStore();
    const routeStore = useRouteStore();
    return {
        coreStore, routeStore
    }
  },
  methods: {
    next: function(){
        if(this.coreStore.instructionsPage>=2){
            this.coreStore.instructionsPage = 0;
            this.coreStore.instructionsOpened = false;
            this.routeStore.increment();

        }else{
            this.coreStore.instructionsPage += 1;
        }
    },
    goBack: function(){
      this.coreStore.instructionsOpened = false;
      this.coreStore.instructionsPage = 0;
    }
  },
  computed: {
    // Your computed properties go here
  },
  mounted() {
    // Code to run when the component is created
  }
}
</script>

<style scoped>
.instructions{
    position: absolute;
    z-index: 99999;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.top_bar_ins{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:5px;
}
.top_bar_ins>img{
  width: 80%;
}

.title{
  color:#CA5C21;
  font-size: 22px;
}

.text_block{
  font-size: 16px;
  color:#70211E;
  min-height: 180px;
  max-height: 200px;
  padding:25px;
  background-color: #f4f4f4;
}

.text_block>p{
  margin-bottom: 5px;
}

.images_block{
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  width: 100%;
  gap:15px
}

.img_text{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.points{
  display:flex;
  flex-direction: row;
  align-self: center;
  gap:8px;
  padding: 20px;
  background-color: #f4f4f4;
  width: 100%;
  justify-content: center;
}

.points>div{
  width: 16px;
  height:16px;
  background-color: rgba(112, 33, 30);
  border-radius: 16px;
}

.next_button{
  border: 2px solid #70211E;
  outline: none;
  color: #70211E;
  font-weight: 500;
  font-size: 18px;
  padding-top:10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
</style>