import { setTimeout } from "core-js";
import { defineStore } from "pinia";
import gsap from "gsap";
import * as THREE from 'three'

export const useRouteStore = defineStore('routeStore',{
    state: () => ({ 
        currentRoute:0 ,
        envRoute:'default'
    }),
    actions:{
        increment(){
            if(this.currentRoute===0){
                this.currentRoute+=1;
                console.log(this.currentRoute);
                document.getElementById('video_zoom').play();
                document.getElementById('video_zoom').addEventListener('ended',()=>{
                    this.currentRoute+=1;  
                    document.getElementById('bgm').play();
                });
            }
        },
        changePlace(place){
            const tl = gsap.timeline({paused:true,
            onComplete:()=>{
                
            }});
            const white = document.getElementById('screen');
            tl.to(white,{display:'block',duration:0});
            tl.to(white,{opacity:1,onComplete: ()=>{
                if(this.envRoute==='rio'){
                    document.getElementById('video').pause();
                    document.getElementById('video').currentTime = 0;
                    document.getElementById('bgm').pause()
                }
                this.envRoute = place;
                if(place==='museo'){
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'false');
                    document.getElementById('camera').components['look-controls'].pitchObject.rotation.set(0,0,0);   
                    document.getElementById('camera').components['look-controls'].yawObject.rotation.set(0,0,0);
                    document.getElementById('camera').setAttribute('camera','fov', '95');
                    document.getElementById('rig').object3D.position.z = 0;
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'true');
                    document.getElementById('bgm').pause();
                }else if(place==='lagar'){
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'false');
                    document.getElementById('camera').components['look-controls'].yawObject.rotation.set(0,1.45,0);
                    document.getElementById('rig').setAttribute('position','0 0 0');
                    document.getElementById('camera').setAttribute('camera','fov', '100');
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'true');
                    document.getElementById('bgm').pause();
                }else if(place==='rio'){
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'false');
                    document.getElementById('camera').components['look-controls'].pitchObject.rotation.set(0,0,0);   
                    document.getElementById('camera').components['look-controls'].yawObject.rotation.set(0,-1.75,0);
                    document.getElementById('rig').setAttribute('position','0 0 0');
                    document.getElementById('camera').setAttribute('camera','fov', '100');
                    document.getElementById('video').muted = false;
                    document.getElementById('video').loop = true;
                    document.getElementById('video').play();
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'true');
                    document.getElementById('bgm').pause()
                }else if(place==='default'){
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'false');
                    document.getElementById('rig').setAttribute('position','0 1.8 .8');
                    document.getElementById('camera').setAttribute('camera','fov', '90');
                    document.getElementById('camera').components['look-controls'].yawObject.rotation.set(0,-2.16,0);
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'true');
                    document.getElementById('bgm').play();
                }
                
            },duration:.5});
            tl.to(white,{opacity:0,onComplete: ()=>{
                console.log('showing')
             },duration:.5,});
            tl.to(white,{display:'none',duration:0});
            tl.play();
        }
    }
})