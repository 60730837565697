<template>
<div class="modal_e_container" v-show="this.coreStore.elaborationShown === true">
    <div class="opt_1" style="background-image: url('/assets/img/e1.png');" @click="play_0()">
        TRABAJO EN LA VIÑA
    </div>
    <div class="opt_2" style="background-image: url('/assets/img/e2.png');" @click="play_1()">
        ELABORACIÓN DEL VINO
    </div>
    <button class="close" @click="close()">X</button>
</div>
</template>

<script>
import { useCoreStore } from '@/stores/coreStore';

export default{
    name:'ModalElaboracionComponent',
    setup(){
        const coreStore = useCoreStore();
        return {
            coreStore
        }
    },
    methods:{
        close: function(){
            this.coreStore.elaborationShown = false;
        },
        play_0:function(){
            this.coreStore.videoSelected = 5;
            this.coreStore.videoPlayerShown = true;
        },
        play_1:function(){
            this.coreStore.videoSelected = 6;
            this.coreStore.videoPlayerShown = true;
        }
    }
}
</script>

<style>
.modal_e_container{
    padding: 20px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    color:#f4f4f4;
    background-color: #CA5C21;
    font-weight: 600;
    font-size:22px;
    gap:10px
}

.opt_1{
    width: 256px;
    height: 256px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.opt_2{
    width: 256px;
    height: 256px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.close{
    color:#f4f4f4;
    font-family:'Inter';
    width: 48px;
    height: 48px;
    border:none;
    outline:none;
    border-radius: 24px;
    background-color: red;
    font-weight: 600;
    position: absolute;
    top:-14px;
    right:-14px
}
</style>