<template v-if="routeStore.envRoute === 'lagar'">
        <a-entity id="lagar" gltf-model="url(/assets/models/lagar.glb)" :scale="routeStore.envRoute==='lagar' ? '3 3 3' : '0 0 0'" :data-raycastable="this.routeStore.envRoute==='lagar'?'':null" lagar></a-entity>
    
        
</template>

<script>
import { useRouteStore } from '@/stores/RouteStore';
import '@/helpers/lagar_component';



export default{
    name:'LagarComponent',
    setup(){
        const routeStore = useRouteStore();
        return{
            routeStore
        }
    },
    data() {
        return {
            loaded:false
        }
    },
    methods:{
        setLoaded: function(){
            this.loaded = true;
        }
    },
    mounted(){
        
    }
}
</script>


<style scoped>

</style>