<template v-if="routeStore.envRoute==='museo'">
        <a-entity id="museo" gltf-model="url(/assets/models/escenario_1.glb)"  :scale="routeStore.envRoute==='museo' ? '3 3 3' : '0 0 0'" position="0 1 -1" :data-raycastable="this.routeStore.envRoute==='museo'?'':null" museo animation-mixer="clip:RootAction"></a-entity>
        <QuizComponent/>
</template>

<script>
import { useRouteStore } from '@/stores/RouteStore';
import '@/helpers/outside_components';
import QuizComponent from './QuizComponent.vue';
import '@/helpers/extras';


export default{
    name:'MuseoComponent',
    components:{
        QuizComponent
    },
    setup(){
        const routeStore = useRouteStore();
        return{
            routeStore
        }
    },
    data() {
        return {
            loaded:false
        }
    },
    methods:{
        setLoaded: function(){
            this.loaded = true;
            console.log("xd")
        }
    },
    mounted(){
    }
}
</script>


<style scoped>
.ting{
    background-color: #fff;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 999999;
}
</style>