import { setTimeout } from "core-js";
import { defineStore } from "pinia";

export const useCoreStore = defineStore('coreStore',{
    state: () => ({ 
        isLoaded : false,
        videoPlayerShown: false,
        videoSelected: 0,
        instructionsOpened:false,
        instructionsPage:0,
        quizFinished:false,
        currentQuestion: 0,
        score: 0,
        responding:false,
        isModalShown:false,
        elaborationShown:false,
        modal_title:'',
        modal_description:''
    }),
})