<template v-if="this.routeStore.envRoute === 'rio'">
    <a-sphere radius="64" material="src:#video;side:double" v-if="this.routeStore.envRoute === 'rio'" :data-raycastable="this.routeStore.envRoute==='rio'?'':null"></a-sphere>
    <!--<div class="play_button" v-if="routeStore.envRoute === 'rio' && this.played=== false" @click="playVideo()">
            PLAY
        </div>-->

    <a-plane src="./assets/img/info_rio.png" :scale="this.routeStore.envRoute === 'rio' ? '1.7 2.1' : '0 0 0'" position="-2 0 0" rotation="0 96 1.5"></a-plane>
</template>

<script>
import { useRouteStore } from '@/stores/RouteStore'
export default{
    name:'RiverComponent',
    setup(){
        const routeStore = useRouteStore();
        return{
            routeStore
        }
    },
    data(){
        return{
            played:false
        }
    },
    methods:{
        playVideo: function(){
            document.getElementById('video').muted = false;
            document.getElementById('video').play();
            this.played = true;
        }
    },
    mounted(){
        document.getElementById('video').addEventListener('ended', () => {
            this.played = false;
        });
    }
}
</script>

<style>
.play_button{
    width: 48px;
    height: 48px;
    position: fixed;
    z-index: 999999;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
}
</style>