<template v-if="this.routeStore.envRoute === 'museo'">
    <a-entity position="1.4 2 -.1"  :scale="routeStore.envRoute==='museo' ? '.3 .3 .3' : '0 0 0'" rotation="0 -90 0" data-raycastable quiz>
        
        <a-plane id="q" :src="coreStore.currentQuestion===0 ? './assets/img/quiz/quiz_1.png' :
                            coreStore.currentQuestion===1 ? './assets/img/quiz/quiz_2.png' : 
                            coreStore.currentQuestion===2 ? './assets/img/quiz/quiz_3.png' : 
                            coreStore.currentQuestion===3 ? './assets/img/quiz/quiz_4.png' : 
                            coreStore.currentQuestion===4 ? './assets/img/quiz/quiz_5.png' :
                            coreStore.currentQuestion===5 ? './assets/img/quiz/quiz_6.png' : 
                            './assets/img/quiz/quiz_6.png'" 
                            material="transparent:true" position="0 .2 0" :scale="coreStore.quizFinished === true ? '0 0 0' : '2.750 1.4'"></a-plane>

        <a-plane id="q" :src="coreStore.score<=2 ? './assets/img/quiz/a3.png' :
                            coreStore.score>3 && coreStore.score<=5 ? './assets/img/quiz/a2.png' : 
                            coreStore.score>=4 ? './assets/img/quiz/a1.png' :
                            coreStore.score===3 ? './assets/img/quiz/a3.png':
                            './assets/img/quiz/quiz_6.png'" material="transparent:true" position="0 .2 0" :scale="coreStore.quizFinished === true ? '2.750 1.4' : '0 0 0'"></a-plane>

        <a-plane id="r1" :src="coreStore.currentQuestion===0 ? './assets/img/quiz/q1/q1_resp_1.png' : 
                            coreStore.currentQuestion===1 ? './assets/img/quiz/q2/q2_resp_1.png' : 
                            coreStore.currentQuestion===2 ? './assets/img/quiz/q3/q3_resp_1.png' : 
                            coreStore.currentQuestion===3 ? './assets/img/quiz/q4/q4_resp_1.png' : 
                            coreStore.currentQuestion===4 ? './assets/img/quiz/q5/q5_resp_1.png' : 
                            coreStore.currentQuestion===5 ? './assets/img/quiz/q6/q6_resp_1.png' : 
                            './assets/img/quiz/q6/q6_resp_1.png'" 
                            position="0 -.85 0" 
                            :scale="coreStore.quizFinished === true ? '0 0 0' : '2.35 .45'" 
                            :data-raycastable="this.coreStore.responding===false?'':null"
                            r1></a-plane>

        <a-plane id="r2" :src="coreStore.currentQuestion===0 ? './assets/img/quiz/q1/q1_resp_2.png' : 
                            coreStore.currentQuestion===1 ? './assets/img/quiz/q2/q2_resp_2.png' : 
                            coreStore.currentQuestion===2 ? './assets/img/quiz/q3/q3_resp_2.png' : 
                            coreStore.currentQuestion===3 ? './assets/img/quiz/q4/q4_resp_2.png' : 
                            coreStore.currentQuestion===4 ? './assets/img/quiz/q5/q5_resp_2.png' : 
                            coreStore.currentQuestion===5 ? './assets/img/quiz/q6/q6_resp_2.png' : 
                            './assets/img/quiz/q6/q6_resp_2.png'" 
                            position="0 -1.45 0" 
                            :scale="coreStore.quizFinished === true ? '0 0 0' : '2.35 .45'"
                            :data-raycastable="this.coreStore.responding===false?'':null" r2></a-plane>

        <a-plane id="r3"  :src="coreStore.currentQuestion===0 ? './assets/img/quiz/q1/q1_resp_3.png' : 
                            coreStore.currentQuestion===1 ? './assets/img/quiz/q2/q2_resp_3.png' : 
                            coreStore.currentQuestion===2 ? './assets/img/quiz/q3/q3_resp_3.png' : 
                            coreStore.currentQuestion===3 ? './assets/img/quiz/q4/q4_resp_3.png' : 
                            coreStore.currentQuestion===4 ? './assets/img/quiz/q5/q5_resp_3.png' : 
                            coreStore.currentQuestion===5 ? './assets/img/quiz/q6/q6_resp_3.png' : 
                            './assets/img/quiz/q6/q6_resp_3.png'" 
                            position="0 -2.05 0" 
                            :scale="coreStore.quizFinished === true ? '0 0 0' : '2.35 .45'"
                            :data-raycastable="this.coreStore.responding===false?'':null" r3></a-plane>

<a-plane id="retry" src="./assets/img/quiz/bt_reintentar.png" 
     position="0 -1.45 0" :scale="coreStore.quizFinished === true ? '2.35 .45' : '0 0 0'" data-raycastable retry></a-plane>                    
    </a-entity>
</template>

<script>
import { useCoreStore } from '@/stores/coreStore';
import { useRouteStore } from '@/stores/RouteStore';
import '@/helpers/quiz_component';

export default{
    name:'QuizComponent',
    setup(){
        const coreStore = useCoreStore();
        const routeStore = useRouteStore();
        return {
            coreStore,routeStore
        }
    }
}
</script>
