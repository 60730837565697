import AFRAME from 'aframe';
import { useRouteStore } from '@/stores/RouteStore';



AFRAME.registerComponent('outside',{
    init: function(){
        const scene = document.getElementById('scene').object3D;
        const routeStore = useRouteStore();
        console.log('component initialized');
    
        this.el.addEventListener('model-loaded',()=>{
        console.log('model loaded')
        });
        this.el.addEventListener('click',(e)=>{
            let current = e.detail.intersection.object.name;
            console.log(current);
            switch(current){
                case 'BT_Lagar':
                    console.log('lagar');
                    routeStore.changePlace('lagar');
                    break;
                case 'BT_Museo':
                    console.log('museo');
                    routeStore.changePlace('museo');
                    break;
                case 'BT_Rio':
                    console.log('rio');
                    routeStore.changePlace('rio');
                    break;
            }
        })
    },
    tick: function(){
        //document.getElementById('rig').object3D.rotation.y += .02
        //console.log(document.getElementById('rig').object3D.rotation.y);
        //document.getElementById('camera').object3D.rotation.y = 0;
        //console.log(document.getElementById('camera').object3D.rotation.y);
    }
});