import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import aframe from 'aframe';
const app = createApp(App);
const pinia = createPinia();


app.use(pinia);
app.use(aframe);
app.mount('#app');

