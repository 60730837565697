import AFRAME from 'aframe';
import { useRouteStore } from '@/stores/RouteStore';
import { useCoreStore } from '@/stores/coreStore';




AFRAME.registerComponent('lagar',{
    init: function(){
        const scene = document.getElementById('scene').object3D;
        const routeStore = useRouteStore();
        const coreStore = useCoreStore();
        console.log('lagar initialized');
        
        this.el.addEventListener('click',(e)=>{
            let current = e.detail.intersection.object.name;
            console.log(current);
            const viga = 'https://studio.onirix.com/modelviewer/ae51baec74164b64b260688cd325320c?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY1NzkxOCwicm9sZSI6OCwiaWF0IjoxNjc2Mzc2MzAyfQ.pOlNuDMGa4ZkFdxrp7WvkQqo_Xf-g8bpjj33nBqJP5U';
            console.log(current)
            if(current==='BT_AR'){
                window.open(viga, '_blank').focus();
            }else if(current === 'BT_Video'){
                coreStore.videoPlayerShown = true;
                coreStore.videoSelected = 4;
            }
        })
    },
    tick: function(){
        
    }
});