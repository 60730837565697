import AFRAME from 'aframe';
import { useRouteStore } from '@/stores/RouteStore';
import { useCoreStore } from '@/stores/coreStore';




AFRAME.registerComponent('museo',{
    init: function(){
        const scene = document.getElementById('scene').object3D;
        const routeStore = useRouteStore();
        const coreStore = useCoreStore();
        console.log('component initialized');

        const barril = 'https://studio.onirix.com/modelviewer/ea5da500c10b4aa3af7b733d24d6a43c?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY0ODQ1Niwicm9sZSI6OCwiaWF0IjoxNjc1OTE0MTU3fQ.ebMh7_QHf_QS7zFNzSxzqjwH6EtV-mBMEHjYDWjbT58';
        const jarra = 'https://studio.onirix.com/modelviewer/28ea4dc2cead4d49bb89cb5b08eeaaa3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY1NzkxOSwicm9sZSI6OCwiaWF0IjoxNjc2Mzc2NjAwfQ.i4pftXa-WpQAzApIc1m3QhwbAACtn4tNmA1GZGcfiPs';
        const cesto = 'https://studio.onirix.com/modelviewer/39a31cf849ab408b939d5b77fd612e9e?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsImFzc2V0SWQiOjY0ODQ1OCwicm9sZSI6OCwiaWF0IjoxNjc1OTE0NTc4fQ.yAPc6mEC6qcbvJk7JNixXcZZ_w1cjnNPfbx0UXDxrEc';
    
        this.el.addEventListener('model-loaded',()=>{
        console.log('model loaded');
        });
        this.el.addEventListener('click',(e)=>{
            let current = e.detail.intersection.object.name;
            console.log(current);
            switch(current){
                case 'bb_historia':
                    console.log('historia');
                    coreStore.videoSelected = 0;
                    coreStore.videoPlayerShown = true;
                    break;
                case 'bb_terreno':
                    console.log('terreno');
                    coreStore.videoSelected = 1;
                    coreStore.videoPlayerShown = true;
                    break;
                case 'bb_fab':
                    console.log('fabricacion');
                    //coreStore.videoSelected = 2;
                    //coreStore.videoPlayerShown = true;
                    coreStore.elaborationShown = true;
                    break;
                case 'bb_dop':
                    console.log('denominacion de origen');
                    coreStore.videoSelected = 3;
                    coreStore.videoPlayerShown = true;
                    break;
                case 'bb_barril':
                    console.log('barril');
                    coreStore.modal_title = 'Barrica';
                    coreStore.modal_description = 'Barrica hecha mediante el vaciado del tronco de un castaño usada para el almacenamiento y conservación del vino. Se trata de una barrica excepcional al ser de una única pieza. Para la construcción de barricas la sociedad tradicional usaba tanto roble como castaño.'
                    coreStore.isModalShown = true;
                    break;
                case 'bb_jarra':
                    console.log('jarra');
                    coreStore.modal_title = 'Puchera';
                    coreStore.modal_description = 'Jarra de barro de color rojizo usada para medir y vender vino. Su capacidad se aproxima al medio litro. Su uso era muy habitual no solo en bodegas sino en chigres y bares.'
                    coreStore.isModalShown = true;
                    break;
                case 'bb_cesto':
                    console.log('cesto');
                    coreStore.modal_title = 'Goxo';
                    coreStore.modal_description = 'Cesto de madera, generalmente avellano, de base ancha, cuyas paredes se van cerrando hacía la boca, adquiriendo así un aspecto de cesto de forma redondeada. Usado en las vendimias su capacidad oscila entre los 40-80 kilos de uva. La forma del Museo es un homenaje a estos cestos y a los cesteiros.'
                    coreStore.isModalShown = true;
                    break;
            }
        })
    },
    tick: function(){
        
    }
});