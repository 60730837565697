<template>
    <div id="loading_screen" v-show="routeStore.currentRoute === 2 && coreStore.isLoaded === false" style="background-image: url('./assets/img/bg.png');">
        <div class="main_logo">
      <img src="/assets/img/logo_museo.png" alt="">
    </div>
    <div class="box">
     <div class="container">
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
    </div>
    </div>
    </div>
    <div id="screen" class="white_screen"></div>
    <div class="test" v-if="routeStore.currentRoute === 2">
    <a-scene renderer="antialias: true; colorManagement: true;" id="scene" scenecomponent
    device-orientation-permission-ui="deviceMotionMessage:Esta experiencia necesita obtener permisos de orientación del dispositivo;allowButtonText:Confirmar;denyButtonText:Cancelar">
        <a-assets>
            <a-asset-item id="test" src="/assets/models/entrada_v1.glb"></a-asset-item>
            <a-asset-item id="test" src="/assets/models/lagar.glb"></a-asset-item>
            <a-asset-item id="test" src="/assets/models/escenario_1.glb"></a-asset-item>
            <video id="video" src="/assets/video/rio_hi.mp4" crossorigin="anonymous" playsinline muted autoplay="false"></video>
            <img src="/assets/img/quiz/quiz_1.png" alt="">
            <img src="/assets/img/quiz/quiz_2.png" alt="">
            <img src="/assets/img/quiz/quiz_3.png" alt="">
            <img src="/assets/img/quiz/quiz_4.png" alt="">
            <img src="/assets/img/quiz/quiz_5.png" alt="">
            <img src="/assets/img/quiz/quiz_6.png" alt="">

            <img src="/assets/img/quiz/q1/q1_resp_1.png" alt="">
            <img src="/assets/img/quiz/q1/q1_resp_2.png" alt="">
            <img src="/assets/img/quiz/q1/q1_resp_3.png" alt="">

            <img src="/assets/img/quiz/q2/q2_resp_1.png" alt="">
            <img src="/assets/img/quiz/q2/q2_resp_2.png" alt="">
            <img src="/assets/img/quiz/q2/q2_resp_3.png" alt="">

            <img src="/assets/img/quiz/q3/q3_resp_1.png" alt="">
            <img src="/assets/img/quiz/q3/q3_resp_2.png" alt="">
            <img src="/assets/img/quiz/q3/q3_resp_3.png" alt="">

            <img src="/assets/img/quiz/q4/q4_resp_1.png" alt="">
            <img src="/assets/img/quiz/q4/q4_resp_2.png" alt="">
            <img src="/assets/img/quiz/q4/q4_resp_3.png" alt="">

            <img src="/assets/img/quiz/q5/q5_resp_1.png" alt="">
            <img src="/assets/img/quiz/q5/q5_resp_2.png" alt="">
            <img src="/assets/img/quiz/q5/q5_resp_3.png" alt="">

            <img src="/assets/img/quiz/q6/q6_resp_1.png" alt="">
            <img src="/assets/img/quiz/q6/q6_resp_2.png" alt="">
            <img src="/assets/img/quiz/q6/q6_resp_3.png" alt="">

        </a-assets>
        <a-entity id="rig" position="0 1.8 .8">
        <a-entity id="camera" camera="fov:90" look-controls></a-entity>
        </a-entity>


        <a-entity 
        id="mouse_cursor"
        cursor="rayOrigin:mouse;fuseTimeout:0"
        raycaster="objects: [data-raycastable]"></a-entity>
        
        <a-entity id="ting" gltf-model="url(/assets/models/entrada_v1.glb)" :scale="routeStore.envRoute==='default' ? '3 3 3' : '0 0 0'" :data-raycastable="this.routeStore.envRoute==='default'?'':null" outside></a-entity>
        <LagarComponent/>
        <MuseoComponent/>
        <RiverComponent/>
        
    </a-scene>
    </div>
</template>

<script>
import { useRouteStore } from '@/stores/RouteStore';
import '@/helpers/outside_components';
import '@/helpers/museo_component';
import LagarComponent from './LagarComponent.vue';
import MuseoComponent from './MuseoComponent.vue';
import { useCoreStore } from '@/stores/coreStore';
import RiverComponent from './RiverComponent.vue';
import QuizComponent from './QuizComponent.vue';


export default{
    name:'OutsideComponent',
    components:{
    LagarComponent,
    MuseoComponent,
    RiverComponent,
    QuizComponent
},
    setup(){
        const routeStore = useRouteStore();
        const coreStore = useCoreStore();
        return{
            routeStore,
            coreStore
        }
    },
    methods:{
        setLoaded: function(){
            this.coreStore.isLoaded = true
        }
    },  
    mounted(){
        const setLoaded = () => this.setLoaded();
        
        AFRAME.registerComponent('scenecomponent', {
            init:function(){
                document.getElementById('scene').addEventListener('loaded',()=>{
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'false');
                    document.getElementById('camera').components['look-controls'].yawObject.rotation.set(0,-2.16,0);
                    document.getElementById('camera').setAttribute('look-controls','enabled', 'true');
                setLoaded();
            })
            }
        }) 
    }
}
</script>


<style scoped>
#loading_screen{
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    top: 0;
    bottom: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.white_screen{
    display: none;
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99999999999;
    opacity: 0;
}

.main_logo>img{
    width:100%
}

.box {
	 width: 300px;
	 height: 300px;
	 background: transparent;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .container {
	 height: 15px;
	 width: 105px;
	 display: flex;
	 position: relative;
}
 .container .circle {
	 width: 15px;
	 height: 15px;
	 border-radius: 50%;
	 background-color: #70211E;
	 animation: move 500ms linear 0ms infinite;
	 margin-right: 30px;
}
 .container .circle:first-child {
	 position: absolute;
	 top: 0;
	 left: 0;
	 animation: grow 500ms linear 0ms infinite;
}
 .container .circle:last-child {
	 position: absolute;
	 top: 0;
	 right: 0;
	 margin-right: 0;
	 animation: grow 500ms linear 0s infinite reverse;
}
 @keyframes grow {
	 from {
		 transform: scale(0, 0);
		 opacity: 0;
	}
	 to {
		 transform: scale(1, 1);
		 opacity: 1;
	}
}
 @keyframes move {
	 from {
		 transform: translateX(0px);
	}
	 to {
		 transform: translateX(45px);
	}
}
 
.a-dialog{
    background-color: #70211E;
    color:#fff;
    text-align: center;
}

.a-dialog-deny-button {
    background-color: transparent;
    border-radius: 25px;
    color:#fff;
    border:1px solid #f4f4f4;
  }
  
.a-dialog-allow-button {
    background-color: #f4f4f4;
    color:#CA5C21;
    border-radius: 25px;
}
</style>