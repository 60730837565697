<template>
    <div class="video_container" v-show="this.routeStore.currentRoute == 1">
        <video preload="auto" id="video_zoom" src="/assets/video/zoom.mp4" playsinline muted ></video>
    </div>
</template>

<script>
import { useRouteStore } from '@/stores/RouteStore';
    export default{
        name:'VideoPlayer',
        setup(){
        const routeStore = useRouteStore();
        return{
        routeStore
        }
  },
    }
</script>

<style scoped>
    .video_container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    #video_zoom{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

</style>