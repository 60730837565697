<template>
  <div class="top_bar" v-if="this.routeStore.envRoute != 'default'">
    <div class="back_container"  @click="goBack">
    <img src="/assets/img/back.svg" alt="">
    </div>
  </div>
  <audio id="bgm" src="/assets/misc/bgm.mp3" loop playsinline></audio>
  <StartScreen/>
  <InstructionsComponent/>
  <VideoComponent/>
  <OutsideComponent/>
  <VideoPlayer/>
  <ModalComponent/>
  <ModalElaboracionComponent/>
</template>

<script>
import StartScreen from './components/StartScreen.vue';
import VideoComponent from './components/VideoComponent.vue';
import OutsideComponent from './components/OutsideComponent.vue';
import VideoPlayer from './components/VideoPlayerContainer.vue';
import { useRouteStore } from './stores/RouteStore';
import InstructionsComponent from './components/InstructionsComponent.vue';
import ModalComponent from './components/ModalComponent.vue';
import ModalElaboracionComponent from './components/ModalElaboracionComponent.vue';

export default {
  name: 'App',
  components: {
    StartScreen,
    VideoComponent,
    OutsideComponent,
    VideoPlayer,
    InstructionsComponent,
    ModalComponent,
    ModalElaboracionComponent
},
  setup(){
    const routeStore = useRouteStore();
    return{
      routeStore
    }
  },
  methods:{
    goBack:function(){
      this.routeStore.changePlace('default');
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.button_primary{
  background-color: #CA5C21;
  color:#fff;
  border:none;
  width:60%;
  padding: 10px;
  font-weight: 600;
}

.button_primary_back{
  background-color: #CA5C21;
  color:#fff;
  border:none;
  padding: 10px;
  font-weight: 600;
  margin: 10px;
}

.back_container{
  margin:10px;
  background-color: rgba(255,255,255,.7);
  padding: 5px;
  border-radius: 8px;
}

.button_secondary{
  background-color: transparent;
  color: #CA5C21;
  border:1px solid #000;
  width:60%;
  padding: 10px;
  font-weight: 600;
}

.top_bar{
  position:absolute;
  z-index: 999;
  top:0;
  left:0
}
#app {
  
}
</style>


