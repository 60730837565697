import AFRAME from 'aframe';
import { useRouteStore } from '@/stores/RouteStore';
import { useCoreStore } from '@/stores/coreStore';

function quizFlow(id,q){
    const coreStore = useCoreStore();
    if(q === true){
        document.getElementById(id).setAttribute('material', 'color:green');
        coreStore.responding = true;
        setTimeout(()=>{
            coreStore.currentQuestion +=1;
            document.getElementById(id).removeAttribute('material');
            coreStore.responding = false;
        },500);
        coreStore.score +=1;
    }else if(q === false){
        document.getElementById(id).setAttribute('material', 'color:red');
        coreStore.responding = true;
        setTimeout(()=>{
            coreStore.currentQuestion +=1;
            document.getElementById(id).removeAttribute('material');
            coreStore.responding = false;
        },500); 
    }

    if(coreStore.currentQuestion === 5){
        coreStore.quizFinished = true;
    }
}


AFRAME.registerComponent('quiz',{
    init: function(){
        console.log('quiz mounted');
        this.el.addEventListener('click', (e)=>{
            let current = e.detail.intersection.object.el.object3D.name;
            console.log(current);
            const coreStore = useCoreStore();
            if(current==='retry'){
                coreStore.quizFinished = false;
                coreStore.currentQuestion = 0;
                coreStore.score = 0;
            }
            if(coreStore.currentQuestion === 0){
                if(current === 'r1'){
                    quizFlow('r1',false);
                }
                else if(current === 'r2'){
                    quizFlow('r2',true);
                }
                else if(current === 'r3'){
                    quizFlow('r3',false);
                }
            }
            else if(coreStore.currentQuestion === 1){
                if(current === 'r1'){
                    quizFlow('r1',false);
                }
                else if(current === 'r2'){
                    quizFlow('r2',false);
                }
                else if(current === 'r3'){
                    quizFlow('r3',true);
                }
            }
            else if(coreStore.currentQuestion === 2){
                if(current === 'r1'){
                    quizFlow('r1',false);
                }
                else if(current === 'r2'){
                    quizFlow('r2',true);
                }
                else if(current === 'r3'){
                    quizFlow('r3',false);
                }
            }
            else if(coreStore.currentQuestion === 3){
                if(current === 'r1'){
                    quizFlow('r1',true);
                }
                else if(current === 'r2'){
                    quizFlow('r2',false);
                }
                else if(current === 'r3'){
                    quizFlow('r3',false);
                }
            }
            else if(coreStore.currentQuestion === 4){
                if(current === 'r1'){
                    quizFlow('r1',false);
                }
                else if(current === 'r2'){
                    quizFlow('r2',false);
                }
                else if(current === 'r3'){
                    quizFlow('r3',true);
                }
            }
            else if(coreStore.currentQuestion === 5){
                if(current === 'r1'){
                    quizFlow('r1',true);
                }
                else if(current === 'r2'){
                    quizFlow('r2',false);
                }
                else if(current === 'r3'){
                    quizFlow('r3',false);
                }
            }
        });
    }
});

AFRAME.registerComponent('r1',{
    init: function(){
        this.el.object3D.name = 'r1';
    }
});


AFRAME.registerComponent('r2',{
    init: function(){
        this.el.object3D.name = 'r2';
    }
});


AFRAME.registerComponent('r3',{
    init: function(){
        this.el.object3D.name = 'r3';
    }
});

AFRAME.registerComponent('retry',{
    init: function(){
        this.el.object3D.name = 'retry';
    }
});